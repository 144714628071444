<template>
  <div>
    <tabBar :mouseenterIndex="20" />
    <div class="home">
      <div class="cenbenters">
        <div class="bannertop">
          <el-carousel
            :interval="3000"
            height="43.5rem"
            :autoplay="true"
            :indicator-position="'none'"
            @change="changeBanner"
            ref="carousel"
          >
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <div class="banner_div">
                <img
                  class="bgimgs"
                  :src="item.img"
                  alt=""
                  @click="topagestype(item)"
                />
                <!-- <div class="bannerposi">
                  <div class="bannertext">01</div>
                  <div class="bannertext_center">
                    {{ item.name }}
                  </div>
                  <div class="toBtn">适合你的牙齿美白</div>
                  <div class="banner_bootm">
                    <div></div>
                    <div class="smiles">·Smile</div>
                  </div>
                </div> -->
              </div>
            </el-carousel-item>
          </el-carousel>
          <!-- <div class="zhishideng">
            <bannerReduis
              :bannerList="bannerList.length"
              @enterIndex="enterIndex"
              :activeIndex="activeindex"
            ></bannerReduis>
          </div> -->
          <triangle
            :nums="10"
            :viewbox="'0 0 1920 215'"
            :pathpoin="'1920 289.4 0 289.4 0 22 960 172.1 1920 22'"
            :compoin="'1920 0 1920 22 960 173.1 0 22 0 0 960 150.1'"
          ></triangle>
          <!-- <img class="homeredus" src="../assets/img/home1.png" alt=""> -->
        </div>
      </div>
      <div class="hometitles">
        <p>Opalescence</p>
        <p>奥普斯牙齿美白</p>
      </div>
      <div class="homecenter">
        作为拥有30多年牙齿美白经验，全球牙齿美白病例超过 1
        亿人次的专业牙齿美白领导者。
        无论您是想居家美白牙齿，还是想快速的美白牙齿，Opalescence（奥普斯）
        牙齿美白系统都可以满足您亮白牙齿的个性需求。
      </div>
      <div :style="'height:' + 320 / 16 + 'rem'">
        <homeDrag></homeDrag>
      </div>
      <div class="texthomes">
        <p>将滑块向右滑动查看美白前的照片，然后向左滑动查看</p>
        <p>Opalescence奥普斯出色的牙齿美白效果！</p>
      </div>
      <div class="ullist">
        <div class="lis" @click="topages(1)">
          <img class="imgposi one" src="../assets/img/yaci.png" alt="" />
          <div class="positions">
            牙齿美白的工作原理<span class="spans">>></span>
          </div>
        </div>
        <div class="lis bgs" @click="topages(2)">
          <img class="imgposi two" src="../assets/img/link-mapPin.png" alt="" />
          <div class="positions">
            寻找您附近的美白牙齿<span class="spans">>></span>
          </div>
        </div>
        <div class="lis" @click="topages(3)">
          <img
            class="imgposi ther"
            src="../assets/img/toothpaste-productpackaging.png"
            alt=""
          />
          <div class="positions">进一步了解<span class="spans">>></span></div>
        </div>
      </div>
      <div class="nav_bg_one">
        <div class="nanbgone_center">
          <div class="nav_bg_one_left">
            <img
              class="navimgone"
              src="../assets/img/toothpaste-productpackaging.png"
              alt=""
            />
            <p class="onetitle">这就是你需要找的</p>
            <p class="onetitle">抗敏美白牙膏</p>
            <p class="navtext">
              我们知道您会喜欢我们屡获殊荣的 <br />奥普斯®美白牙膏。
            </p>
            <div class="bgone_btn" @click="topages(3)">了解更多信息</div>
          </div>
          <!-- <img
            class="imgads"
            src="../assets/img/third-banner-model.png"
            alt=""
          /> -->
        </div>
        <!-- <triangle style="z-index: 99"></triangle> -->
      </div>
      <div class="nanv_cen_title">
        看看大家都是怎么评价 <br />
        OPALESCENCE™奥普斯®牙齿美白的？
      </div>
      <div class="banner_nav">
        <div class="banner_navcenter">
          <el-carousel
            height="510px"
            :autoplay="false"
            :indicator-position="'none'"
          >
            <el-carousel-item>
              <div class="navbanner_cen">
                <div class="navbanner_top">
                  <img class="leftimgs" src="../assets/img/ping1.png" alt="" />
                  <div class="nav_icons">
                    <!-- <div class="iconone"></div> :autoplay="false"-->
                    <img
                      class="iconone"
                      src="../assets/img/douyin.png"
                      alt=""
                      @click="todouying(1)"
                    />
                    <img
                      class="iconone"
                      src="../assets/img/xiaohongshu.png"
                      alt=""
                      @click="todouying(2)"
                    />
                  </div>
                </div>
                <div class="bgcenter">
                  <img
                    class="centers"
                    src="../assets/img/xiexian2.png"
                    alt=""
                  />
                </div>
                <div class="banner_bottom">
                  <div class="names">@小姜不爱吃姜_（复旦版）</div>
                  <div class="texts">
                    带着没有任何不适，淡淡的薄荷味心也很舒服，我一般带着看剧，一集韩剧看完差不多就取掉，七天下来能有这个效果我觉得真滴很不错在此，再次避雷舒克牙贴！他家牙膏不错，但是牙贴真的有点一言难尽，（狗头.ipg）相比之下奥普斯他家带上下牙托真的方便很多！（具体
                    vs可以看我前面具体对比过这两个牙贴！）
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="navbanner_cen">
                <div class="navbanner_top">
                  <img class="leftimgs" src="../assets/img/ping2.png" alt="" />
                  <div class="nav_icons">
                    <img
                      class="iconone"
                      src="../assets/img/douyin.png"
                      alt=""
                      @click="todouying(1)"
                    />
                    <img
                      class="iconone"
                      src="../assets/img/xiaohongshu.png"
                      alt=""
                      @click="todouying(2)"
                    />
                  </div>
                </div>
                <div class="bgcenter">
                  <img
                    class="centers"
                    src="../assets/img/xiexian2.png"
                    alt=""
                  />
                </div>
                <div class="banner_bottom">
                  <div class="names">@喵嗷毛毛</div>
                  <div class="texts">
                    一打开牙贴就有淡淡的薄荷味标识很清楚，U是上牙，L是下牙。外面有一个绿色的塑料牙套，超级方便使用。只需要直接拿掉，里面的透明牙贴就整齐套住整排牙齿，不仅牙齿外侧，上方和里面都可以贴住。牙贴需要贴30分钟，贴完就去刷牙。整个牙齿感觉很干净清爽，体验感很棒！！
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="navbanner_cen">
                <div class="navbanner_top">
                  <img class="leftimgs" src="../assets/img/ping3.png" alt="" />
                  <div class="nav_icons">
                    <img
                      class="iconone"
                      src="../assets/img/douyin.png"
                      alt=""
                      @click="todouying(1)"
                    />
                    <img
                      class="iconone"
                      src="../assets/img/xiaohongshu.png"
                      alt=""
                      @click="todouying(2)"
                    />
                  </div>
                </div>
                <div class="bgcenter">
                  <img
                    class="centers"
                    src="../assets/img/xiexian2.png"
                    alt=""
                  />
                </div>
                <div class="banner_bottom">
                  <div class="names">@Angel安</div>
                  <div class="texts">
                    在白牙的道路上我真的有话说！高中的时候看到闺蜜再用美牙仪，然后就跟风也入手了一套。虽说确实是白了但是因为要长时间佩戴，我的牙齿撑不住
                    30min就酸酸的，也因为不是次拋的，所以平时用都要特意清洗很久，一直都没能坚持下来。
                    然后自己在牙科诊所买了 Opalescence
                    奥普斯牙贴，我才真正感受到用牙贴真的香，因为是次抛设计卫生度
                    upup，也因为这款牙贴是隐形的，随时随地都可以取戴，每天贴个
                    30-60min，不过我都是选择晚上睡觉时戴上，哈哈哈哈哈因为不可避免的口水是会增多的
                    躺着就不会有这么多的口水了。
                    还有牙齿上会出现白斑是正常现象，24-48h后就会恢复正常，大家使用下来如果也和我有一样的情况，不用担心哦～坚持使用就可以均匀啦，我坚持使用了一周了。
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="navbanner_cen">
                <div class="navbanner_top">
                  <img class="leftimgs" src="../assets/img/ping4.png" alt="" />
                  <div class="nav_icons">
                    <img
                      class="iconone"
                      src="../assets/img/douyin.png"
                      alt=""
                      @click="todouying(1)"
                    />
                    <img
                      class="iconone"
                      src="../assets/img/xiaohongshu.png"
                      alt=""
                      @click="todouying(2)"
                    />
                  </div>
                </div>
                <div class="bgcenter">
                  <img
                    class="centers"
                    src="../assets/img/xiexian2.png"
                    alt=""
                  />
                </div>
                <div class="banner_bottom">
                  <div class="names">@大碗国强</div>
                  <div class="texts">
                    爱喝奶茶 咖啡的宝子们举个手！你们的牙齿一 肯定很黄吧
                    笑起来一口大黄牙也泰 尴尬啦~ 牙齿的护理炒鸡重要滴
                    我每年都会洗牙，最近去做了种植牙手术 恩说我牙齿有点发黄
                    建议我注意牙齿美白，So我入手了奥普斯随时白牙贴，每①贴都是独立包装
                    干净又卫生。
                    之前没有佩戴过这种类型的牙贴，第一次戴牙贴有点不适应，但是第二次就已经适应了
                    and我第一次戴完有牙齿脱水的情况，出现了白斑，但是第二天就恢复了，咨询过都是正常滴做完美白的现象，不用太担心哦～
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="nav_bg_two">
        <div class="nanbgtwo_center">
          <div class="nav_bg_two_left">
            <img class="navimgone" src="../assets/img/tuopanlsitd.png" alt="" />
            <p class="onetitle">独家专利UltraFit™托盘设计</p>
            <p class="onetitle">每一副都是你的专属美白托盘</p>

            <p class="navtext">
              随时随地，即取即用，<br />
              Opalescnece GO™ 随时白®牙齿美白托盘，<br />
              给你带来极致舒适的牙齿美白体验！
            </p>
            <div class="bgone_btn" @click="topages(4)">了解更多信息</div>
          </div>
          <!-- <img
            class="imgads"
            src="../assets/img/second-banner-model.png"
            alt=""
          /> -->
        </div>
        <!-- <triangle style="z-index: 99"></triangle> -->
      </div>
      <div class="problem" @click="toFAQ('')">常见问题</div>
      <div class="ullistproblem">
        <div
          class="lisproblem"
          :class="
            index == 0
              ? 'bgasone'
              : index == 1
              ? 'bgastwo'
              : index == 2
              ? 'bgasther'
              : ''
          "
          v-for="(item, index) in problems"
          :key="index"
          @click="toFAQ(item.questions)"
        >
          <div class="positionsproblem positionsproblemonce">
            <div class="gongzuoyuanli">{{ item.questions }}</div>
            <div class="gongzuo_text">
              {{ item.answer }}
            </div>
          </div>
        </div>
      </div>
      <div class="problem">博客精选</div>
      <div class="fot_swiper">
        <swiper :options="swiperOptions" ref="mySwiper">
          <swiper-slide v-for="(itme, index) in forums" :key="index">
            <div
              class="lisproblem"
              :class="index == 1 ? '' : 'padse'"
              :style="
                'background: url(' +
                itme.img +
                ') no-repeat;background-size: 100% 100%;'
              "
              @click="toblodes(itme.id)"
            >
              <div class="positionsproblem">{{ itme.title }}</div>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-button-prev"
          slot="button-prev"
          @click="prev(index)"
        ></div>
        <div
          class="swiper-button-next"
          slot="button-next"
          @click="next(index)"
        ></div>
      </div>

      <div class="sherchscenter">
        <div class="sherchtitle">Opalescence奥普斯 牙齿美白系统</div>
        <div class="sherchs">
          <div class="sherchtext">
            如有更多相关问题，可在下面搜索解答您的问题！
          </div>
          <div class="inputsher">
            <input class="sherchleft" v-model="sheracname" @keyup.enter='toFAQs' />
            <div class="sherchright" @click="toFAQs">搜索</div>
          </div>
        </div>
      </div>
      <footers></footers>
    </div>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import bannerReduis from "@/components/bannerReduis.vue";
import homeDrag from "@/components/homeDrag.vue";
import footers from "@/components/footer.vue";
import { getYakeIndex } from "../request/homeApi";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "Home",
  components: {
    tabBar,
    triangle,
    bannerReduis,
    homeDrag,
    footers,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      sheracname: "",
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        setWrapperSize: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      activeindex: 0,
      objHome: {},
      problems: [], //常见问题
      forums: [], //博客精选
      bannerList: [],
    };
  },
  mounted() {
    this.lsitad();
  },
  methods: {
    todouying(index) {
      if (index == 1) {
        window.open("https://v.douyin.com/NUoh5tC/", "_blank");
      }
      if (index == 2) {
        window.open(
          "https://www.xiaohongshu.com/user/profile/604ad5d6000000000101f550?xhsshare=WeixinSession&appuid=5b231542f7e8b97c04c89a1a&apptime=1647312375",
          "_blank"
        );
      }
    },
    prev(index) {
      this.$refs.mySwiper.$swiper.slidePrev(index);
    },
    next(index) {
      this.$refs.mySwiper.$swiper.slideNext(index);
    },
    topages(index) {
      if (index == 1) {
        this.$router.push({ path: "/principle" });
      } else if (index == 2) {
        this.$router.push({ path: "/lookingdentist" });
      } else if (index == 3) {
        this.$router.push({ path: "/scheme" });
      } else if (index == 4) {
        this.$router.push({ path: "/skinwhitening" });
      }
    },
    toFAQ(questions) {
       this.$router.push({
          path: "/FAQ?questions=" + questions,
        });
    },
    toFAQs(){
       this.$router.push({
          path: "/compareschers?sheracname=" + this.sheracname,
        });
    },
    toblodes(id) {
      this.$router.push({ path: "/blogsdetail?id=" + id });
    },
    changeBanner(e) {
      //console.log(e);
      this.activeindex = e;
    },
    topagestype(item) {
      if (item.linkType == 0) {
        this.$router.push({ path: "/activityORintegral?active=2" });
      }
      if (item.linkType == 1) {
        this.$router.push({ path: "/activityORintegral?active=1" });
      }
      if (item.linkType == 2) {
        this.$router.push({ path: "/shopdet?id=" + item.goodsId });
      }
			if (item.linkType == 3&&item.path) {
				window.location.href=item.path
			}
      console.log(item);
    },
    enterIndex(e) {
      this.$refs.carousel.setActiveItem(e);
      this.activeindex = e;
    },
    lsitad() {
      getYakeIndex()
        .then((res) => {
          console.log(res.code);
          if (res.code == 200) {
            this.objHome = res.result;
            this.bannerList = this.objHome.advertisements;
            this.problems = this.objHome.problems;
            this.forums = this.objHome.forums;
          }
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding-top: 80px;
}
.cenbenters {
  // margin-top: 90px;
}
.bannertop {
  width: 100%;
  height: 770px;
  position: relative;
  .zhishideng {
    position: absolute;
    max-width: 1250px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 250px;
    z-index: 999;
  }
  .banner_div {
    cursor: pointer;
    height: 100%;
    position: relative;
    .bgimgs {
      width: 100%;
      height: 100%;
    }
    .bannerposi {
      max-width: 1250px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      // background: red;
      .bannertext {
        font-size: 60px;
        color: #ffffff;
      }
      .bannertext_center {
        margin-top: 172px;
        font-size: 64px;
        color: #ffffff;
      }
      .toBtn {
        width: 198px;
        height: 60px;
        line-height: 60px;
        margin-top: 30px;
        text-align: center;
        background: linear-gradient(90deg, #15a9e2, #15a9e2);
        box-shadow: 0px 10px 40px 0px rgba(7, 115, 155, 0.34);
        border-radius: 4px;
        color: #ffffff;
      }
      .banner_bootm {
        display: flex;
        justify-content: space-between;
        .smiles {
          margin-top: 150px;
          text-align: right;
          font-size: 110px;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }
}

.hometitles {
  margin: 20px 0 34px;
  text-align: center;
  font-size: 40px;
  color: #0075aa;
  p {
    margin: 0;
    padding: 0;
  }
}
.homecenter {
  max-width: 795px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  color: #535353;
  line-height: 26px;
}
.texthomes {
  width: 616px;
  // height: 64px;
  margin: 0 auto;
  font-size: 24px;
  margin-top: 30px;
  color: #535353;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: center;
  p {
    margin: 0;
  }
}
.ullist {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 160px;
  .lis {
    position: relative;
    width: 390px;
    height: 240px;
    background: #e7e7e7;
    cursor: pointer;
    .imgposi {
      position: absolute;
      left: 0;
      right: 0;
      top: -50px;
      margin: auto;
      transition: all 0.6s;
    }
    .one {
      width: 192px;
      height: 278px;
    }
    .one:hover {
      transform: scale(0.8);
    }
    .two {
      width: 162px;
      height: 232px;
    }
    .two:hover {
      transform: scale(0.8);
    }
    .ther {
      width: 294px;
      height: 242px;
    }
    .ther:hover {
      transform: scale(0.8);
    }
    .positions {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 60px;
      text-align: center;
      line-height: 60px;
      background: rgba(255, 255, 255, 0.5);
      font-size: 20px;
      color: #333333;
      .spans {
        padding-left: 14px;
      }
    }
  }
  .bgs {
    background: url("../assets/img/link-map-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 38px;
  }
}
.nav_bg_one {
  max-width: 100%;
  height: 798px;
  position: relative;
  margin-top: 140px;
  background: url("../assets/img/bgone.png") no-repeat;
  background-size: 100% 100%;

  .nanbgone_center {
    max-width: 1250px;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    .nav_bg_one_left {
      width: 460px;
      margin-top: 130px;
      margin-left: 20px;
      text-align: center;
      z-index: 100 !important;
      p {
        margin: 0;
        padding: 0;
      }
      .navimgone {
        width: 277px;
        height: 226px;
      }
      .onetitle {
        font-size: 36px;
        color: #009c93;
      }
      .navtext {
        font-size: 24px;
        color: #333333;
        line-height: 40px;
        margin-top: 10px;
      }
      .bgone_btn {
        width: 198px;
        height: 50px;
        margin: 0 auto;
        line-height: 50px;
        text-align: center;
        background: #00aba1;
        border-radius: 4px;
        font-size: 16px;
        color: #ffffff;
        margin-top: 30px;
        z-index: 100;
        cursor: pointer;
      }
      .bgone_btn:hover{
			background: #1ed3ca;
		}
    }
    .imgads {
      position: absolute;
      right: 49px;
      bottom: -422px;
      width: 749px;
    }
  }
}
.nanv_cen_title {
  width: 786px;
  height: 85px;
  text-align: center;
  margin: 115px auto;
  font-size: 40px;
  color: #333333;
}
.banner_nav {
  width: 100%;
  display: flex;
  justify-content: center;
  .banner_navcenter {
    width: 1250px;
    //height: 600px;
    box-shadow: 0px 8px 11px 0px rgba(158, 166, 169, 0.21);
    .navbanner_cen {
      width: 100%;
      position: relative;
      .navbanner_top {
        display: flex;
        justify-content: space-between;
        .leftimgs {
          width: 330px;
          height: 248px;
        }
        .nav_icons {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          margin-right: 12px;
          .iconone {
            width: 35px;
            height: 35px;
            border-radius: 50%;

            // background: red;
          }
        }
      }
      .banner_bottom {
        width: 100%;
        height: 338px;
        padding: 50px 37px 0;
        margin-top: 20px;
        .names {
          text-align: center;
          font-size: 28px;
          color: #333333;
        }
        .texts {
          display: inline-block;
          margin-top: 10px;
          font-size: 18px;
          color: #666666;
        }
      }
      .bgcenter {
        width: 99.5%;
        height: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 212px;
        margin: auto;
        background: #ffffff;
        // clip-path: polygon(-2% 99%, 100% 0, 100% 100%, 0% 100%);
        .centers {
          width: 100%;
          // height: 124px;
          // background: #ffffff;
          // clip-path: polygon(3% 100%, 100% 0, 100% 100%, 0% 100%);
          position: absolute;
          bottom: 90px;
          left: -2px;
        }
      }
    }
  }
}
::v-deep.el-carousel__indicators--horizontal {
  position: absolute;
  left: auto;
  right: 10px;
  bottom: 10px;
  text-align: right;
}
::v-deep.el-carousel__indicators--horizontal .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: #0075aa;
  border-radius: 50%;
}
::v-deep.el-carousel__indicator--horizontal button {
  width: 10px !important;
  height: 10px !important;
  background: #0075aa;
  border-radius: 50%;
}

::v-deep .el-carousel__indicator--horizontal.is-active button {
  width: 10px;
  height: 10px;
  background: #0075aa;
  border-radius: 50%;
}

.nav_bg_two {
  max-width: 100%;
  height: 798px;
  position: relative;
  margin-top: 140px;
  background: url("../assets/img/second-banner-bg.png") no-repeat;
  background-size: 100% 100%;

  .nanbgtwo_center {
    max-width: 1250px;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    .nav_bg_two_left {
      width: 570px;
      margin-top: 60px;
      text-align: center;
      // z-index: 100;
      p {
        margin: 0;
        padding: 0;
      }
      .navimgone {
        width: 357px;
        height: 276px;
      }
      .onetitle {
        font-size: 36px;
        color: #009a44;
      }
      .navtext {
        font-size: 24px;
        color: #333333;
        line-height: 40px;
        margin-top: 10px;
      }
      .bgone_btn {
        width: 198px;
        height: 50px;
        margin: 0 auto;
        line-height: 50px;
        text-align: center;
        background: #009a44;
        border-radius: 4px;
        font-size: 16px;
        color: #ffffff;
        margin-top: 30px;
        cursor: pointer;
      }
      .bgone_btn:hover{
         background: #00c664;
      }
      
    }
    .imgads {
      position: absolute;
      left: 49px;
      bottom: -658px;
      width: 578px;
    }
  }
}
.problem {
  padding: 90px 0;
  text-align: center;
  font-size: 40px;
  color: #333333;
}

.ullistproblem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1250px;
  margin: 0 auto;
  margin-bottom: 40px;
  .lisproblem {
    position: relative;
    width: 390px;
    height: 320px;
    background: #e7e7e7;
    cursor: pointer;
    .imgposiproblem {
      width: 100%;
      height: 100%;
      transition: all 0.6s;
    }
    .positionsproblem {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 60px;
      text-align: center;
      line-height: 60px;
      background: rgba(255, 255, 255, 0.5);
      font-size: 20px;
      color: #333333;
      .gongzuoyuanli {
        width: 100%;
        font-size: 20px;
        color: #535353;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .gongzuo_text {
        width: 100%;
        font-size: 14px;
        color: #333333;
        opacity: 0.55;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .positionsproblemonce {
      text-align: left;
      line-height: initial;
      padding: 4px 26px;
    }
  }
  .padse {
    margin: 0 40px;
  }
  .bgasone {
    background: url("../assets/img/cj1.png") no-repeat;
    background-size: 100% 100%;
  }
  .bgastwo {
    background: url("../assets/img/cj2.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 40px;
  }
  .bgasther {
    background: url("../assets/img/cj3.png") no-repeat;
    background-size: 100% 100%;
  }
  .bgsone {
    background: url("../assets/img/link-map-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 38px;
  }
  .bgstwo {
    background: url("../assets/img/link-map-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 38px;
  }
  .bgsther {
    background: url("../assets/img/link-map-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 38px;
  }
}
.sherchtitle {
  width: 386px;
  height: 85px;
  text-align: center;
  margin: 80px auto;
  font-size: 40px;
  color: #0076ae;
}
.sherchscenter {
  width: 100%;
  height: 100%;
  background: url("../assets/img/bgs.png") no-repeat;
  background-size: 100%;
}
.sherchs {
  width: 1250px;
  margin: 0 auto;
  .sherchtext {
    margin-bottom: 54px;
    text-align: center;
    font-size: 30px;
    color: #535353;
  }
  .inputsher {
    width: 100%;
    padding-bottom: 167px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .sherchleft {
      width: 75%;
      height: 80px;
      background: #ffffff;
      border: 1px solid #bebebe;
      border-radius: 1px;
       font-size: 28px;
      padding: 0 26px;
    }
    .sherchright {
      width: 332px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      background: #0075a9;
      border-radius: 1px;
      font-size: 40px;
      color: #f8f8f8;
    }
  }
}
.fot_swiper {
  width: 1250px;
  margin: 0 auto;
  position: relative;
  .lisproblem {
    position: relative;
    width: 390px;
    height: 260px;
    background: #e7e7e7;
    cursor: pointer;
    .imgposiproblem {
      width: 100%;
      height: 100%;
      transition: all 0.6s;
    }
    .positionsproblem {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 60px;
      text-align: center;
      line-height: 60px;
      background: rgba(255, 255, 255, 0.5);
      font-size: 20px;
      color: #333333;
      .gongzuoyuanli {
        width: 100%;
        font-size: 20px;
        color: #535353;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .gongzuo_text {
        width: 100%;
        font-size: 14px;
        color: #333333;
        opacity: 0.55;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .positionsproblemonce {
      text-align: left;
      line-height: initial;
      padding: 4px 26px;
    }
  }

  // margin-top: 0.59rem;
  // padding: 0 2.37rem;
  // box-sizing: border-box;
  // position: relative;
  .swiper-button-prev,
  .swiper-button-next {
    color: #00d577;
    font-weight: bold;
    outline: none;
  }
  .swiper-button-prev {
    left: -100px;
  }
  .swiper-button-next {
    right: -100px;
  }
  // .swiper-wrapper {
  //     display: flex;
  //     flex-flow: row;
  //     align-items: center;
  //     height: 1.26rem;
  //   }
  // .swiper-slide {
  //     display: flex;
  //     flex-flow: row;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // .sild {
  //   width: 3.26rem;
  //   height: 1.1rem;
  //   display: flex;
  //   flex-flow: column;
  //   justify-content: center;
  //   align-items: center;
  //   box-shadow: 0px 0.08rem 0.08rem 0.02rem rgba(246, 246, 246, 0.9);
  //   img {
  //     height: 1.1rem;
  //     display: block;
  //   }
  // }
}
</style>
