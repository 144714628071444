<template>
  <div>
    <div class="tabbars">
      <img
        class="bgimgs"
        @click="tohomes"
        src="../assets/img/logotops.png"
        alt=""
      />
      <div class="rights">
        <div class="uls">
          <div
            v-for="(item, index) in titiList"
            :key="index"
            :to="item.url"
            @click="activeClick(index, item.url)"
            @mouseenter="enter(index)"
            :class="activeIndex == index ? 'licard borders' : 'licard'"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="heider_right">
          <p class="texts" v-if="!user">
            <span class="noes" @click="tologin">您好请登录</span>
            <span class="twos" @click="toregists">免费注册</span>
          </p>
          <p class="namesad" style="#15A9E2 " v-if="user" @click="tomepage">
            <img
              style="width: 30px; border-radius: 50%; height: 30px"
              :src="user.face"
              v-if="user.face"
            />
            <img
              v-else
              style="width: 20px; height: 20px"
              src="../assets/img/users.png"
            />
            <!-- <i class="el-icon-s-custom"></i> -->
            <span class="names">{{ user.nickName }}</span>
          </p>
          <p class="dengji" v-if="user">
            <img
              class="imgs"
              v-if="user.levelId == 0"
              src="../assets/tong.png"
              alt=""
            />
            <img
              class="imgs"
              v-if="user.levelId == 1"
              src="../assets/yin.png"
              alt=""
            />
            <img
              class="imgs"
              v-if="user.levelId == 2"
              src="../assets/jin.png"
              alt=""
            />
          </p>
          <!-- <p class="couwuce">
            <b @click="cards"><i class="el-icon-shopping-cart-1"></i></b>
            <b @click="sherch" style="margin-left: 27px"
              ><i class="el-icon-search"></i
            ></b>
          </p> -->
          <p class="guojialsit" style="margin-left: 24px" @click="isremoveclick">
            <img
              style="width: 24px; border-radius: 50%; height: 24px"
              src="../assets/img/web/china.png"
              alt=""
            />
            <b style="margin-left: 5px"><i class="el-icon-caret-bottom"></i></b>
          </p>
          <div class="tuichutabr" v-if="isremove">
            <div class="sanjiaoxng"></div>
            <div
              v-for="(item, index) in lsitari"
              :key="index"
              @click="actives(index, item.url)"
              class="lis"
              :class="fillgs == index ? 'listwo' : ''"
              :style="index == lsitari.length - 1 ? 'border:none;' : ''"
            >
							<el-badge :is-dot='item.code>0' class="item">{{ item.name }}</el-badge>
            </div>
          </div>
          <div class="tuitabr" v-if="isguojia">
            <div class="sanoxng"></div>
            <div class="flexcard">
              <div class="carleft">
                <div
                  class="lsit"
                  v-for="(items, index) in leftList"
                  :key="index"
                  @click="toaibupages(items.url)"
                >
                  <img style="margin-right: 10px" :src="items.icon" alt="" />
                  <span>{{ items.name }}</span>
                </div>
              </div>
              <div class="cardright">
                <div
                  class="lsit"
                  v-for="(items, index) in leftRight"
                  :key="index"
                  @click="toaibupages(items.url)"
                >
                  <img style="margin-right: 10px" :src="items.icon" alt="" />
                  <span>{{ items.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- v-if="isfor" @mouseleave='enters' -->
    <!-- 牙齿美白方案二级菜单 -->
    <div class="flessadtop" v-if="isfor" @mouseleave="enters">
      <ul class="ulicon">
        <li
          v-for="(item, index) in ListCard"
          :key="index"
          class="liicon"
          :class="item.iskeyfor ? 'liicon_actv' : ''"
          @click="gotos(item.path)"
          @mouseover="move(item)"
          @mouseleave="remove(item)"
        >
          <img
            style="height: 30px"
            :src="item.img2"
            v-if="item.iskeyfor || activeIndexs == index"
          />
          <img
            style="height: 30px"
            :src="item.img1"
            v-if="!item.iskeyfor && activeIndexs != index"
          />
          <div
            class="textNamen"
            :class="activeIndexs == index ? 'text_actv' : ''"
          >
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
    <!-- 社区二级菜单 -->
    <div class="headers" v-if="isforum" @mouseleave="enters">
      <div class="headss">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="tips"
          :class="item.iskeyforum ? 'active_tip' : ''"
          @click="gotos(item.path)"
          @mouseover="moves(item)"
          @mouseleave="removes(item)"
        >
          <img
            :src="item.imgs"
            v-if="item.iskeyforum || activeIndexsa == index"
          />
          <img
            :src="item.img"
            v-if="!item.iskeyforum && activeIndexsa != index"
          />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getuserMembers, getUserInfo,shield } from "../request/homeApi";
export default {
  data() {
    return {
      //cardIndex: 100,
      isremove: false,
      isguojia: false,
      leftList: [
        {
          name: "Australia",
          icon: require("../assets/img/web/AS.png"),
          url: "https://www.opalescence.com/au",
        },
        {
          name: "Czech Republic",
          icon: require("../assets/img/web/cze.png"),
          url: "https://www.opalescence.com/cz/Pages/default.aspx",
        },
        {
          name: "Denmark",
          icon: require("../assets/img/web/drackse.png"),
          url: "https://www.opalescence.com/dk",
        },
        {
          name: "Europe",
          icon: require("../assets/img/web/eur.png"),
          url: "https://www.opalescence.com/eu",
        },
        {
          name: "Finland",
          icon: require("../assets/img/web/fin.png"),
          url: "https://www.opalescence.com/fi",
        },
        {
          name: "France",
          icon: require("../assets/img/web/fra.png"),
          url: "https://www.opalescence.com/fr",
        },
        {
          name: "Germany",
          icon: require("../assets/img/web/gre.png"),
          url: "https://www.opalescence.com/de",
        },
        {
          name: "Italy",
          icon: require("../assets/img/web/itl.png"),
          url: "https://www.opalescence.com/it",
        },
        {
          name: "Japan",
          icon: require("../assets/img/web/jpan.png"),
          url: "https://www.opalescence.jp",
        },{
          name: "Latin America",
          icon: require("../assets/img/web/LatinAmerica.png"),
          url: "https://www.opalescence.com/la/Pages/default.aspx",
        },
        {
          name: "Malaysia",
          icon: require("../assets/img/web/Malaysia.png"),
          url: "https://www.opalescence.com/my",
        },
      ],
      leftRight: [
        
        {
          name: "Netherlands",
          icon: require("../assets/img/web/Netherlands.png"),
          url: "https://www.opalescence.com/nl",
        },
        {
          name: "Norway",
          icon: require("../assets/img/web/Norway.png"),
          url: "https://www.opalescence.com/no",
        },
        {
          name: "Poland",
          icon: require("../assets/img/web/Poland.png"),
          url: "https://www.opalescence.com/pl/Pages/default.aspx",
        },
        {
          name: "Singapore",
          icon: require("../assets/img/web/Singapore.png"),
          url: "https://www.opalescence.com/sg",
        },
        {
          name: "South Africa",
          icon: require("../assets/img/web/SouthAfrica.png"),
          url: "https://www.opalescence.com/za",
        },
        {
          name: "Spain",
          icon: require("../assets/img/web/Spain.png"),
          url: "https://www.opalescence.com/es",
        },
        {
          name: "Sweden",
          icon: require("../assets/img/web/Sweden.png"),
          url: "https://www.opalescence.com/se",
        },
        {
          name: "Thailand",
          icon: require("../assets/img/web/Thailand.png"),
          url: "https://www.opalescence.com/th/Pages/default.aspx",
        },
        {
          name: "Turkey",
          icon: require("../assets/img/web/Turkey.png"),
          url: "https://www.opalescence.com/tr",
        },
        {
          name: "USA",
          icon: require("../assets/img/web/usa.png"),
          url: "https://www.opalescence.com",
        },
      ],
      lsitari: [
        {
          name: "个人信息",
          url: "/mepages/index?active=0",
        },
        {
          name: "我的订单",
          url: "/mepages/myoder?active=1",
					code:0
        },
        {
          name: "我的帖子",
          url: "/mepages/posts?active=2",
        },
        {
          name: "我的消息",
          url: "/mepages/news?active=3",
					code:0
        },
        {
          name: "积分商城",
          url: "/activityORintegral?active=2",
        },
		{
		  name: "领券中心",
		  url: "/mepages/couponcoll?active=11",
		},
        {
          name: "退出",
          url: "/",
        },
      ],
      aa: "aaaaa",
      titiList: [
        {
          name: "牙齿美白方案",
          url: "/scheme",
          id: 0,
        },
        {
          name: "社区",
          url: "/compare",
          id: 1,
        },
        {
          name: "奥普斯商城",
          url: "/opusmall",
          id: 2,
        },
        {
          name: "寻找牙医",
          url: "/lookingdentist",
          id: 3,
        },
        {
          name: "关于我们",
          url: "/aboutus",
          id: 4,
        },
        {
         name: "FAQ",
          url: "/FAQ",
          id: 5,
        },
      ],
      isshow: false,
      user: "",
      activeIndex: 200,
      fillgs: 100,
      ListCard: [
        {
          id: 0,
          name: "美白牙膏",
          icom: "el-icon-s-marketing",
          img1: require("../assets/img/yagao.png"),
          img2: require("../assets/img/yg2.png"),
          path: "/scheme",
          iskeyfor: false,
        },
        {
          id: 1,
          name: "美白托盘",
          icom: "el-icon-s-flag",
          img1: require("../assets/img/tuopan.png"),
          img2: require("../assets/img/tuopan2.png"),
          path: "/skinwhitening",
          iskeyfor: false,
        },
        {
          id: 3,
          name: "牙齿美白原理",
          icom: "el-icon-s-management",
          img1: require("../assets/img/yunixng.png"),
          img2: require("../assets/img/yunixng2.png"),
          path: "/principle",
          iskeyfor: false,
        },
      ],
      isfor: false,
      list: [
        {
          name: "博客",
          img: require("../assets/img/xie/boke.png"),
          imgs: require("../assets/img/xie/boke2.png"),
          path: "/compare",
          iskeyforum: false,
        },
        {
          name: "论坛",
          img: require("../assets/img/xie/luntan2.png"),
          imgs: require("../assets/img/xie/luntan.png"),
          path: "/forumlist",
          iskeyforum: false,
        }
      ],
      isforum: false,
			shietypes:0
    };
  },
  props: {
    cardIndex: {
      type: Number, //指定传入的类型
      //type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: 200, //这样可以指定默认的值, 一把指定默认值为空
    },
    activeIndexs: {
      type: Number,
      default: 100,
    },
    activeIndexsa: {
      type: Number,
      default: 100,
    },
    mouseenterIndex: {
      type: Number, //指定传入的类型
      //type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: 200, //这样可以指定默认的值, 一把指定默认值为空
    },
    modalisShow: {
      type: Boolean, //指定传入的类型
      //type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false, //这样可以指定默认的值, 一把指定默认值为空
    },
  },
  watch: {
    cardIndex(newVal) {
      this.activeIndex = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
    },
  },
  mounted() {
    this.activeIndex = this.cardIndex;
    let token = localStorage.getItem("token");
		this.shields()
    //let user = localStorage.getItem("user");
    if (token) {
      this.getuser();
      // if (user) {
      //   this.user = JSON.parse(user);
      // } else {
      //   getuserMembers()
      //     .then((res) => {
      //       if (res.code == 200) {
      //         localStorage.setItem("user", JSON.stringify(res.result));
      //         this.user = res.result;
      //       }
      //     })
      //     .catch((err) => {

      //     });
      // }
    }
  },
  methods: {
    isremoveclick(){
      this.isguojia = !this.isguojia
    },
    toaibupages(url){
       window.open(url, '_blank');
       this.isguojia = false;
    },
    //获取用户信息
    async getuser() {
      let res = await getUserInfo();
      //console.log(res)
      this.user = res.result.member;
			this.lsitari[1].code=res.result.orderMsgCount
			this.lsitari[3].code=res.result.msgCount
      localStorage.setItem("user", JSON.stringify(res.result.member));
    },
    //个人中心菜单跳转
    actives(index, url) {
      this.isremove = false;
      if (index == 6) {
        localStorage.clear();
        this.user = "";
      }
			if(index==1){
				this.$router.push({ path: url,query:{code:this.lsitari[1].code} });
			}else if(index==3){
				this.$router.push({ path: url,query:{code:this.lsitari[3].code} });
			}else{
				this.$router.push({ path: url });
			}
    },
    //跳转注册
    toregists() {
			// if(this.shietypes==1){
			// 	this.$message.error('此功能正在建设中，敬请期待')
			// 	return false
			// }
      this.$router.push({ path: "/register" });
    },
    //跳转登陆
    tologin() {
			// if(this.shietypes==1){
			// 	this.$message.error('此功能正在建设中，敬请期待')
			// 	return false
			// }
      this.$router.push({ path: "/login" });
    },
    sing() {
      this.isremove = false;
    },
    //弹出个人信息菜单
    tomepage() {
      this.isremove = !this.isremove;
      let sk = this.$route.fullPath;
      if (sk.includes("mepages")) {
        if (this.$route.query.active <= 3) {
          this.fillgs = this.$route.query.active;
        } else if(this.$route.query.active==11) {
          this.fillgs = 5;
        }else{
					this.fillgs = 100;
				}
      }
			if(sk.includes('activityORintegral')&&this.$route.query.active==2){
				this.fillgs = 4;
			}
    },
    //跳转购物车
    cards() {
      if (this.user) {
        this.$emit("meactive", 1);
        this.$router.push({ path: "/mepages/mycart?active=6&page=0&chind=3" });
      } else {
        this.$message({
          showClose: true,
          message: "请先登录",
          type: "error",
        });
      }
    },
    //跳转商城
    sherch() {
      this.$router.push({ path: "/OpusMall" });
    },
    //返回首页
    tohomes() {
      this.$router.push({ path: "/" });
    },
    //一级菜单鼠标移入
    enter(index) {
      if (index == 0) {
        this.isfor = true;
        //console.log(this.activeIndexs)
      } else {
        this.isfor = false;
      }
      if (index == 1) {
        this.isforum = true;
      } else {
        this.isforum = false;
      }
    },
    //二级菜单鼠标移出
    enters() {
      this.isfor = false;
      this.isforum = false;
    },
    //牙齿美白二级菜单鼠标移入
    move(item) {
      this.ListCard.forEach((item, index) => {
        item.iskeyfor = false;
      });
      this.$set(item, "iskeyfor", true);
      this.$forceUpdate();
    },
    //牙齿美白二级菜单鼠标移出
    remove() {
      this.ListCard.forEach((item, index) => {
        item.iskeyfor = false;
      });
      this.$forceUpdate();
    },
    //社区二级菜单鼠标移入
    moves(item) {
      this.list.forEach((item, index) => {
        item.iskeyforum = false;
      });
      this.$set(item, "iskeyforum", true);
      this.$forceUpdate();
    },
    //社区二级菜单鼠标移出
    removes() {
      this.list.forEach((item, index) => {
        item.iskeyforum = false;
      });
      this.$forceUpdate();
    },
    //点击二级菜单跳转
    gotos(path) {
      this.$router.push(path);
    },
    //点击一级菜单跳转
    activeClick(index, url) {
      this.activeIndex = index;
      this.$router.push({ path: url });
    },
		//获取屏蔽接口
		async shields(){
			let res=await shield(2)
			this.shietypes=res.result
			localStorage.setItem("shietype", res.result);
		}
  },
};
</script>

<style lang="scss" scoped>
.tabbars {
  // position: relative;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #ffffff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  .bgimgs {
    cursor: pointer;
    height: 80px;
  }
  .rights {
    width: 62%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .uls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .licard {
        position: relative;
        margin-right: 55px;
        height: 100%;
        line-height: 90px;
        color: #333333;
        font-size: 16px;
        cursor: pointer;
      }
      .borders {
        border-bottom: 2px solid #09a9e2;
        color: #09a9e2;
        font-weight: bold;
      }
    }
    .heider_right {
      // margin-right: 5%;
      display: flex;
      align-items: center;
      position: relative;
      p {
        margin: 0;
        padding: 0;
      }
      .texts {
        .noes {
          cursor: pointer;
          text-decoration: underline;
        }
        .twos {
          padding-left: 4px;
          color: #15a9e2;
          cursor: pointer;
        }
      }
      .namesad {
        color: #15a9e2;
        margin-left: 27px;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        .names {
          font-weight: bold;
          display: inline-block;
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          margin-left: 7px;
        }
      }
      .dengji {
        // font-size: 22px;
        // color: #d8ab33;
        margin-left: 27px;
        width: 22px;
        height: 22px;
        // cursor: pointer;
        .imgs {
          width: 100%;
          //height: 100%;
        }
      }
      .couwuce {
        cursor: pointer;
        color: #050505;
        font-size: 20px;
        margin-left: 27px;
      }
      .tuichutabr {
        width: 155px;
        position: absolute;
        top: 50px;
        left: -36px;
        background: #ffffff;
        box-shadow: 0px 9px 26px 9px rgba(72, 149, 208, 0.19);
        border-radius: 4px;
        padding: 0 18px;
        .sanjiaoxng {
          position: absolute;
          top: -20px;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 20px solid #fff;
					filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, .3));
        }
        .lis {
          width: 100%;
          text-align: center;
          padding: 14px 0;
          border-bottom: 1px solid rgba(73, 75, 77, 0.19);
          font-size: 14px;
          color: #666666;
          cursor: pointer;
        }
        .listwo {
          color: #15a9e2;
        }
        .lis:hover {
          color: #15a9e2;
        }
      }
      .tuitabr {
        width: 400px;
        position: absolute;
        bottom: -448px;
        right: 10px;
        background: #ffffff;
        box-shadow: 0px 9px 26px 9px rgba(72, 149, 208, 0.19);
        border-radius: 4px;
        padding: 0 18px;
        .sanoxng {
          position: absolute;
          top: -20px;
          left: 0;
          right: -304px;
          margin: auto;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 20px solid #ffffff;
        }
        .flexcard {
          display: flex;
          justify-content: space-between;
          padding: 20px;
          font-size: 14px;
          color: #666666;
          .lsit{
            margin-bottom: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.flessadtop {
  width: 100%;
  position: fixed;
  top: 80px;
  height: 85px;
  display: flex;
  align-items: center;
  background-color: rgba(232, 232, 232, 0.8);
  z-index: 998;
  .ulicon {
    width: 50%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .liicon {
      margin: 0px 50px 0 50px;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      font-size: 38px;
      color: #727272;
      .textNamen {
        font-size: 16px;
        color: #727272;
        display: inline-block;
        margin-top: 4px;
      }
    }
    .liicon_actv {
      .textNamen {
        color: #09a9e2;
      }
    }
    .text_actv {
      color: #09a9e2 !important;
    }
  }
}
.headers {
  width: 100%;
  height: 85px;
  background-color: rgba(232, 232, 232, 0.8);
  color: #535353;
  font-size: 16px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 998;
  left: 0;
  top: 80px;
  .headss {
    // margin-left: 80px;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
  .tips {
    margin-right: 95px;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    justify-content: center;
    img {
      width: 33px;
      height: 31px;
    }
  }
  .active_tip {
    color: #00a3e0;
  }
}
.guojialsit {
  width: 70px;
  cursor: pointer;
  .imgsguo {
  }
}
</style>