<template>
  <div :class="bannerList != 1 ? 'drag draglsit' : 'drag heights'">
    <img
      class="imglist before dragtwo"
      v-if="bannerList == 1"
      src="../assets/img/duibi13.png"
      alt=""
    />
    <img
      class="imglist after dragtwo"
      v-if="bannerList == 1"
      :style="'clip: rect(0rem, ' + numbers / 16 + 'rem, 18.5rem, 0rem);'"
      src="../assets/img/duibi12.png"
      alt=""
    />
    <img
      class="imglist before"
      v-if="bannerList != 1"
      src="../assets/img/after.jpg"
      alt=""
    />
    <img
      class="imglist after"
      v-if="bannerList != 1"
      :style="'clip: rect(0rem, ' + numbers / 16 + 'rem, 18.5rem, 0rem);'"
      src="../assets/img/before.jpg"
      alt=""
    />
    <div class="drag_btm">
      <div style="margin-right: 0.625rem">
        <span v-if="bannerList == 1">美白前</span> <span v-else>Before</span>
      </div>
      <div style="margin-left: 0.625rem">
        <span v-if="bannerList == 1">美白后</span> <span v-else>After</span>
      </div>
    </div>
    <div
      class="toplins"
      @mousedown="mousedown"
      :style="{ left: sonLeft / 16 + 'rem', backgroundColor: backgroundColor }"
    >
      <div :class="bannerList == 1? 'centerbgs heightsw':'centerbgs'"></div>
      <img src="../assets/img/homeleft.png" alt="" />
      <img src="../assets/img/homeright.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sleft: 0,
      stop: 0,
      sonLeft: 234,
      sonTop: 0,
      numbers: 275,
    };
  },
  props: {
    backgroundColor: {
      type: String,
      default: "#0077bb",
    },
    bannerList: {
      type: Number,
      default: 0,
    },
  },
  mounted(){
    if(this.bannerList == 1){
       this.sonLeft = 290;
    this.numbers = 330;
    }
   
  },
  methods: {
    mousedown(e) {
      let element = e.currentTarget;
      //   阻止默认的图片拖拽事件
      e.preventDefault();

      //算出鼠标相对元素的位置
      let disX = e.clientX - element.offsetLeft;
      document.onmousemove = (e) => {
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        element.style.cursor = "move";
        let left = e.clientX - disX;
        //移动当前元素
        if (this.bannerList != 1) {
          if (left > 491) {
            left = 491;
          }
        }else{
          if (left > 610) {
            left = 610;
          }
        }

        if (left < -40) {
          left = -40;
        }
        this.sonLeft = left;
        this.numbers = this.sonLeft + 41;
      };
      document.onmouseup = (e) => {
        //鼠标弹起来的时候不再移动
        document.onmousemove = null;
        //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
        document.onmouseup = null;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.drag {
  width: 650px;
  height: 260px;
  // transform: scale(0.6, 0.6);
  // transform-origin: 0% 0%;
  position: relative;
  top: 56px;
  left: 0;
  right: 0;
  margin: auto;
  .imglist {
    width: 100%;
    height: 200px;
  }
  .dragtwo {
    height: 300px;
  }
  .after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    // clip: rect(0px, 400px, 300px, 0px);
  }
  .drag_btm {
    height: 60px;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .toplins {
    width: 82px;
    padding: 0 12px;
    height: 40px;
    background: #0077bb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    // left: 0;
    // right: 0;
    margin: auto;
    .centerbgs {
      position: absolute;
      top: -215px;
      left: 0;
      right: 0;
      margin: auto;
      width: 6px;
      background: hsla(0, 0%, 100%, 0.7);
      box-shadow: 0 0 1px rgb(0 0 0 / 60%);
      height: 215px;
    }
    .heightsw{
      height: 320px;
      top: -320px;
    }
    img {
      width: 18px;
      height: 18px;
    }
  }
  .toplins :hover {
    cursor: pointer;
  }
}
.draglsit {
  width: 535px;
}
.heights{
  height: 360px;
}
.drag {
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}
</style>