<template>
	<svg :style="'position: absolute; bottom:'+ nums +'px;left: 0;z-index:99;'" id="Layer_1" :viewBox="viewbox"
			 preserveAspectRatio="xMidYMax meet" version="1.1" xmlns="http://www.w3.org/2000/svg"
			 xmlns:xlink="http://www.w3.org/1999/xlink">
			<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="GrayChevron">
							<polygon id="Path" :fill="back" :points="pathpoin"></polygon>
							<g id="Page-1" fill="#C2C6CC">
									<polygon id="Combined-Shape" :points="compoin"></polygon>
							</g>
					</g>
			</g>
	</svg>
</template>

<script>
	export default{
		props:{
			back:{
				type: String,
				default: '#FFFFFF',
			},
			viewbox:{
				type:String,
				default:'0 0 1920 215'
			},
			pathpoin:{
				type:String,
				default:'1920 215.5 0 215.5 0 22 960 214.1 1920 22'
			},
			nums:{
				type:Number,
				default: -2
			},
			compoin:{
				type:String,
				default:'1920 0 1920 22 960 215.1 0 22 0 0 960 190.1' 
			}
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style scoped lang="scss">
	svg{
		z-index: 99;
	}
</style>
