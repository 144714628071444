import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/font.scss';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/js/rem.js';
import md5 from 'js-md5';

// import  VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css';
// Vue.use(VueAwesomeSwiper)


Vue.prototype.$md5 = md5;


/* 复制内容到剪切板 */
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
});
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(ElementUI);

ElementUI.Dialog.props.lockScroll.default = false;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
