<template>
	<el-row class="footers">
		<img class="footerimgs" src="../assets/img/listadsfooter.png" @click="towaibulianjie" alt="">
	  <el-col :span="4">
			<div class="grid-content bg-purple">
				<div role='none' class="footer-background"></div>
			</div>
		</el-col>
	  <el-col :span="16">
			<div class="footercont">
				<div class="head">
					<div class="head_a">
						<img class="headimg" src='../assets/img/xie/logos.png' />
						<div><a style="color:#ffffff;" href="tel:+020-84156506">020 -84156506</a></div>
						<div><a style="color:#ffffff;" href="mailto:info@ultradent.com?subject=test&cc=sample@hotmail.com&subject=主题&body=内容">info@ultradent.com</a></div>
						<div style="margin-bottom: 0;">广州市海珠区工业大道北路67号凤凰 创意园34栋102室</div>
					</div>
					<div>
						<div class="head_b">产品</div>
						<div class="head_c">
							<div @click="routs('/scheme')">牙膏</div>
							<div @click="routs('/skinwhitening')">随时白</div>
						</div>
					</div>
					<div>
						<div class="head_b">教育</div>
						<div class="head_c">
							<div @click="gotos('/FAQ')">FAQ</div>
							<div @click="routs('/principle')">美白原理</div>
						</div>
					</div>
					<div>
						<div class="head_b">联系我们</div>
						<div class="contact">
							<div class="contacts" style="margin-right: 24px;">
								<div class="contacts_a">
									<img style="widht:100%;height:100%" src="../assets/img/xie/QQ.png" />
								</div>
								<div class="scana"></div>
								<div class="scan" style="height: 148px;">
									<div class="scan_a">
										<div class="sans">
											<div class="sanj">
												<div></div>
											</div>
											<img src="../assets/img/xie/kefu.png" />
										</div>
									</div>
								</div>
							</div>
							<div class="contacts">
								<div class="contacts_a">
									<img style="widht:100%;height:100%" src="../assets/img/xie/WEIXIN.png" />
								</div>
								<div class="scana"></div>
								<div class="scan">
									<div class="scan_a">
										<div class="sans" style="margin-right: 12px;">
											<div class="sanj">
												<div></div>
											</div>
											<img src="../assets/img/xie/kefu.png" />
											<span>奥普斯客服</span>
										</div>
										<div class="sans">
											<img src="@/assets/img/xie/gonzhonh.jpg" />
											<span>公众号</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div class="head_b" style="text-align: center;">关注</div>
						<div class="contact">
							<div class="contacts" @click="routsa('https://v.douyin.com/NUoh5tC/')">
								<img style="widht:100%;height:100%" src="../assets/img/xie/douyin.png" />
							</div>
							<div class="contacts" style="margin:0 24px;" @click="routsa('https://www.xiaohongshu.com/user/profile/604ad5d6000000000101f550?xhsshare=WeixinSession&appuid=5b231542f7e8b97c04c89a1a&apptime=1647312375')">
								<img style="widht:100%;height:100%" src="../assets/img/xie/xiaohongshu.png" />
							</div>
							<div class="contacts" @click="routsa('https://space.bilibili.com/669315184')">
								<img style="widht:100%;height:100%" src="../assets/img/xie/bili.png" />
							</div>
						</div>
					</div>
				</div>
				<div class="websites">
					<div class="togou">
						<span>互联网ICP备案：</span>
						<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2022156623号-2</a>
					</div>
					<div class="togou" style="margin-top: 5px;">
						<span>互联网药品信息服务资格证书：</span>
						<a :href="link+'#/zhenshu/ypxx'" target="_blank">（粤）—经营性—2022—0308</a>
					</div>
				</div>
				<div class="foot">
					<div>
						<span>© {{date}} Ultradent Products,lnc.保留所有权利。</span>
					</div>
					<div class="foots">
						<div style="padding-left: 0;" @click="gotosa('PRIVACY_SETTING_SIX')">60天保证</div>
						<div @click="gotosa('PRIVACY_SETTING')">隐私政策</div>
						<div @click="gotosa('PRIVACY_SETTING_MANAGER')">隐私管理</div>
						<div style="padding-right: 0;border-right: 0;" @click="gotosa('PRIVACY_SETTING_RULE')">条款和条件</div>
					</div>
				</div>
			</div>
		</el-col>
	  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
	</el-row>
</template>

<script>
	export default{
		data(){
			return{
				link:'',
				date:''
			}
		},
		mounted() {
			let sk=window.location.href.split('#')
			this.link=sk[0]
			this.date=new Date().getFullYear()
		},
		methods:{
			towaibulianjie(){
				 window.open("https://www.ultradent.cn/", "_blank");
			},
			gotos(path){
				this.$router.push({
					path:path
				})
			},
			gotosa(ind){
				this.$router.push({
					path:'/policy',
					query:{type:ind}
				})
			},
			routs(path){
				this.$router.push({
					path:path
				})
			},
			routsa(url){
				if(url){
					window.open(url, '_blank');
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.grid-content {
			border-radius: 4px;
			min-height: 36px;
		}
	.footers{
		width: 100%;
		background-color: #0075aa;
		display: flex;
		.footer-background{
			width: 296px;
			height: 230px;
		}
		.footer-background::before{
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			transform: skew(-52deg);
			left: calc(-100% - -13px);
			border: 0 solid #b1b3b3;
			box-sizing: inherit;
			width: 100%;
			background-color: #C5C5C5;
		}
		.footer-background::after{
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			transform: skew(-52deg);
			left: 12px;
			width: 32px;
			background-color: #BBBBBB;
		}
		.footercont{
			//height: 392px;
			max-width: 1318px;
			padding-top: 58px;
			// display: flex;
			// flex-flow: column;
			// justify-content: space-between;
			.head{
				color: #fff;
				display: flex;
				width: 100%;
				flex-flow: row;
				justify-content: space-between;
				.head_a{
					div{
						width: 270px;
						margin-bottom: 15px;
						font-size: 16px;
					}
					.headimg{
						width: 248px;
						//height: 39px;
						margin-bottom: 27px;
					}
				}
				.contact{
					display: flex;
					.contacts{
						width: 34px;
						height: 34px;
						border-radius: 50%;
						// background-color: #fff;
						cursor: pointer;
						position: relative;
						.contacts_a{
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
						}
						.scana{
							position: absolute;
							height: 16px;
							width: 100%;
							top: 34px;
							left: 0;
						}
						.scan{
							display: none;
							position: absolute;
							top: 48px;
							left: -50px;
							border-radius: 4px;
							height: 186px;
							background-color: #fff;
							z-index: 99;
							padding: 10px 10px 14px 10px;
							box-sizing: border-box;
							.scan_a{
								display: flex;
							}
							.sans{
								position: relative;
								font-size: 14px;
								color: #666666;
								text-align: center;
								img{
									width: 135px;
									height: 129px;
								}
								.sanj{
									position: absolute;
									width: 100%;
									display: flex;
									flex-flow: row;
									justify-content: center;
									top: -18px;
									div{
										margin-left: -20px;
										width: 0;
										height: 0;
										border-left: 10px solid transparent;
										border-right: 10px solid transparent;
										border-bottom: 14px solid #fff;
									}
								}
								span{
									display: inline-block;
									margin-top: 12px;
								}
							}
						}
					}
					.contacts:hover{
						.scan{
							display: block;
						}
					}
				}
				.head_b{
					font-size: 26px;
					margin-bottom: 20px;
				}
				.head_c{
					div{
						margin-bottom: 15px;
						cursor: pointer;
					}
				}
			}
			.websites{
				margin-top: 10px;
				.togou{
				  color: #fff;
					a{
						color: #fff;
					}
					a:hover{
						text-decoration: underline !important;
					}
				}
			}
			.foot{
				margin-top: 30px;
				width: 100%;
				height: 67px;
				border-top: 1px solid #fff;
				display: flex;
				flex-flow: row;
				justify-content: space-between;
				align-items: center;
				color: #fff;
				font-size: 16px;
				.foots{
					display: flex;
					align-items: center;
					div{
						padding: 0 28px;
						border-right: 1px solid #fff;
						cursor: pointer;
					}
				}
			}
		}
		.footerimgs{
			cursor: pointer;
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
</style>
