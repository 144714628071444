<template>
  <div class="uls">
    <div
      class="redius"
      v-for="(item, index) in bannerList"
      :key="index"
      :style="activeIndex == index ? 'border: 2px solid #ffffff;' : ''"
      @mouseenter="enter(index)"
    >
      <div
        class="reds_center"
        :style="activeIndex == index ? '' : 'opacity: 0.55;'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ativeidx: 0,
    };
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    bannerList: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    enter(index) {
    //   this.activeIndex = index;
      this.$emit("enterIndex", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.uls {
  display: flex;
  align-items: center;
  .redius {
    width: 28px;
    height: 28px;
    margin-right: 23px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .reds_center {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border-radius: 50%;
    }
  }
}
</style>