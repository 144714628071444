export default {
     // 基础的请求地址 
     //baseURL: 'https://three.yjxh-tech.com/buyer',
    baseURL: 'https://opalescence.cn/buyer',
     //baseURL: 'http://47.109.85.9:8888/buyer',
    uploadURL:'https://opalescence.cn/common/upload/file',
		//uploadURL:'https://three.yjxh-tech.com/common/upload/file',
		 baseURLCOM:'https://opalescence.cn/common'
		//baseURLCOM:'https://three.yjxh-tech.com/common'
		
}