import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/scheme',
		name: 'scheme',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/scheme.vue')
	},
	{
		path: '/compare',
		name: 'compare',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/compare.vue')
	},
	{
		path: '/opusmall',
		name: 'opusmall',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/OpusMall.vue')
	},
	{
		path: '/lookingdentist',
		name: 'lookingdentist',
		component: () => import(/* webpackChunkName: "about" */ '../views/lookingdentist.vue')
	},
	{
		path: '/dentaldet',
		name: 'dentaldet',
		component: () => import(/* webpackChunkName: "about" */ '../views/dentaldet.vue')
	},
	{
		path: '/aboutus',
		name: 'aboutus',
		component: () => import(/* webpackChunkName: "about" */ '../views/aboutus.vue')
	},
	{
		path: '/principle',
		name: 'principle',
		component: () => import(/* webpackChunkName: "about" */ '../views/principle/principle.vue')
	},
	{
		path: '/FAQ',
		name: 'FAQ',
		component: () => import(/* webpackChunkName: "about" */ '../views/FAQ/FAQ.vue')
	},
	{
		path: '/policy',
		name: 'policy',
		component: () => import('../views/policy/policy.vue')
	},
	{
		path: '/shopdet',
		name: 'shopdet',
		component: () => import('../views/shop/shopdet.vue')
	},
	{
		path: '/skinwhitening',
		name: 'skinwhitening',
		component: () => import('../views/skinwhitening.vue')
	},
	{
		path: '/toothpaste',
		name: 'toothpaste',
		component: () => import('../views/toothpaste.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue')
	},
	{
		path: '/dentists',
		name: 'dentists',
		component: () => import('../views/dentists.vue')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/register.vue')
	},
	{
		path: '/forumlist',
		name: 'forumlist',
		component: () => import('../views/forum/forumlist.vue')
	},
	{
		path: '/forumdet',
		name: 'forumdet',
		component: () => import('../views/forum/forumdet.vue')
	},
	{
		path: '/blogsdetail',
		name: 'blogsdetail',
		component: () => import('../views/blogsdetail.vue')
	},
	{
		path: '/activityORintegral',
		name: 'activityORintegral',
		component: () => import('../views/activityORintegral.vue')
	},{
		path: '/closeORaccountpage',
		name: 'closeORaccountpage',
		component: () => import('../views/closeORaccountpage.vue')
	},{
		path: '/paymentsuccess',
		name: 'paymentsuccess',
		component: () => import('../views/paymentsuccess.vue')
	},{
		path: '/compareschers',
		name: 'compareschers',
		component: () => import('../views/compareschers.vue')
	},
	{
		path: '/zhenshu/ypxx',
		name: 'ypxx',
		component: () => import('../zhenshu/ypxx.vue')
	},
	{
		path: '/mepages/index',
		name: 'mepages/index',
		component: () => import('../views/mepages/index.vue'),
		children: [
			{
				path: '/mepages/myoder',
				name: 'myoder',
				component: () => import('../views/mepages/myoder.vue')
			},
			{
				path: '/mepages/shippingaddress',
				name: 'shippingaddress',
				component: () => import('../views/mepages/shippingaddress.vue')
			},
			{
				path: '/mepages/aftersaleOder',
				name: 'aftersaleOder',
				component: () => import('../views/mepages/aftersaleOder.vue')
			},
			{
				path: '/mepages/mycart',
				name: 'mycart',
				component: () => import('../views/mepages/mycart.vue')
			},
			{
				path: '/mepages/users',
				name: 'users',
				component: () => import('../views/mepages/user/users.vue')
			},
			{
				path: '/mepages/posts',
				name: 'posts',
				component: () => import('../views/mepages/user/posts.vue')
			},
			{
				path: '/mepages/comment',
				name: 'comment',
				component: () => import('../views/mepages/user/comment.vue')
			},
			{
				path: '/mepages/news',
				name: 'news',
				component: () => import('../views/mepages/user/news.vue')
			},
			{
				path: '/mepages/password',
				name: 'password',
				component: () => import('../views/mepages/user/password.vue')
			},
			{
				path: '/mepages/coupon',
				name: 'coupon',
				component: () => import('../views/mepages/user/coupon.vue')
			},
			{
				path: '/mepages/couponcoll',
				name: 'couponcoll',
				component: () => import('../views/mepages/user/couponcoll.vue')
			},
			{
				path: '/mepages/myoderdetail',
				name: 'myoderdetail',
				component: () => import('../views/mepages/myoderdetail.vue')
			},
			{
				path: '/mepages/aftersale',
				name: 'aftersale',
				component: () => import('../views/mepages/aftersale.vue')
			}
		]
	},

]

const router = new VueRouter({
	// mode: 'history',
	routes
})

export default router
