// 刚刚封装的axios
import request from './axios';
import http from './http';

// // 获取图片验证码
// export const getImgCode = () => {
//     return request({
//         url: 'banner',
//         method: 'get',
//         // 图片验证码 response类型设置成blob，图片才能显示出来
//         responseType: "blob"
//     })
// }


// 获取手机验证码
export const getPhoneCode = (param) => {
    return request({
        url: `/sms/${param.verificationEnums}/${param.mobile}`,
        method: 'get',
        baseURL:http.baseURLCOM,
        headers: {
            "uuid": "assd"
        },
    })
}
// 验证手机验证码是否正确
export const getCodesoue = (data) => {
    return request({
        url: `/members/verifyCode`,
        method: 'get',
				headers: {
				    "uuid": "assd"
				},
        params:data
    })
}
// 忘记密码
export const forgetpass = (data) => {
	return request({
		url: `/members/forgetByMobile`,
		method: 'put',
		params:data
	})
}
// 获取首页数据
export const getYakeIndex = (param) => {
    return request({
        url: '/pageData/getYakeIndex?type=0',
        method: 'get',
        params: param
    })
}
// 用户注册
export const postUserRegister = (data) => {
    return request({
        url: '/members/register',
        method: 'post',
        headers: {
            "uuid": "assd"
        },
        params:data
    })
}
// 用户账号密码登录
export const membersuserLogin = (data) => {
    return request({
        url: '/members/userLogin',
        method: 'post',
        headers: {
            "uuid": "assd"
        },
        // data: param1,
        params: data
    })
}
// 用户手机短信登录
export const phoneLogin = (data) => {
    return request({
        url: '/members/smsLogin',
        method: 'post',
        headers: {
            "uuid": "assd"
        },
        // data: param1,
        params: data
    })
}
// 获取登录用户信息
export const getuserMembers = () => {
    return request({
        url: "/members",
        method: 'get',
    })
}
// 获取博客列表
export const getforumLsit = (params) => {
    return request({
        url: "/forum/list",
        method: 'get',
        params
    })
}
// 获取博客详情 
export const getforumdetail = (id) => {
    return request({
        url: `/forum/detail/${id}`,
        method: 'get',
    })
}
// 评论博客forum/read
export const postforumReply = (data) => {
    return request({
        url: "/forumReply",
        method: 'post',
        params: data
    })
}
// 评论博客
export const putaddUp = (id,isDoUp) => {
    return request({
        url: `/forum/addUp/${id}/${isDoUp}`,
        method: 'put',
    })
}
// 浏览数量
export const forumread = (id) => {
    return request({
        url: `/forum/read/${id}`,
        method: 'put',
    })
}
// 个人中心 getUserInfo
export const getUserInfo = () => {
    return request({
        url: `/info/getUserInfo`,
        method: 'get',
    })
}
// 签到
export const postUsersign = () => {
    return request({
        url: `/members/sign`,
        method: 'post',
    })
}
// 获取地区地址
export const getallCity = (id) => {
    return request({
        url: `/region/item/${id}`,
        method: 'get',
        baseURL:http.baseURLCOM,
        headers: {
            "uuid": "assd"
        },
    })
}
// 获取购物车列表
export const getUsercarts = (params) => {
    return request({
        url: `/trade/carts/all`,
        method: 'get',
        params
    })
}
// 删除购物车列表数据 
export const getCartsRemove = (params) => {
    return request({
        url: `/trade/carts/sku/remove?skuIds=` + params,
        method: 'delete',
    })
}
//清空购物车列表数据
export const getCartsAllRemove = () => {
    return request({
        url: `/trade/carts`,
        method: 'delete',
    })
}
// 选中单个 
export const postcartschecked = (data) => {
    return request({
        url: `/trade/carts/sku/checked/${data.skuId}`,
        method: 'post',
        params:data
    })
}
// 修改单个数量
export const postcartsnum = (data) => {
    return request({
        url: `/trade/carts/sku/num/${data.skuId}`,
        method: 'post',
        params:data
    })
}
// 全选
export const postcartstore = (data) => {
    return request({
        url: `/trade/carts/store/${data.storeId}`,
        method: 'post',
        params:data
    })
}
// 新增地址
export const postmemberAddress = (data) => {
    return request({
        url: `/memberAddress`,
        method: 'post',
        params:data
    })
}
// 获取地址
export const getmemberAddress = (params) => {
    return request({
        url: `/memberAddress`,
        method: 'get',
        params
    })
}
// 修改地址
export const putmemberAddress = (params) => {
    return request({
        url: `/memberAddress`,
        method: 'put',
        params
    })
}
// 删除地址
export const deletememberAddress = (id) => {
    return request({
        url: `/memberAddress/delById/${id}`,
        method: 'delete',
    })
}
// 商城首页
export const getGoodsEs = (params) => {
    return request({
        url: `/goods/es`,
        method: 'get',
        params
    })
}
// 积分商品分类
export const getGoodspointsGoodscategory = (params) => {
    return request({
        url: `/promotion/pointsGoods/category`,
        method: 'get',
        params
    })
}
// 积分商品
export const getGoodspointsGoods = (params) => {
    return request({
        url: `/promotion/pointsGoods`,
        method: 'get',
        params
    })
}
// 牙医列表
export const getdentistlist = (params) => {
    return request({
        url: `/dentist/list`,
        method: 'get',
        params
    })
}
// 选择收货地址
export const getshippingAddress = (params) => {
    return request({
        url: `/trade/carts/shippingAddress`,
        method: 'get',
        params
    })
}
// 获取结算也购物车详情
export const getshippingcartschecked = (params) => {
    return request({
        url: `/trade/carts/checked`,
        method: 'get',
        params
    })
}
// 获取购物车优惠券
export const getcouponcanUse = (params) => {
    return request({
        url: `/promotion/coupon/canUse`,
        method: 'get',
        params
    })
}
// 提交订单
export const postcreatetrade = (data) => {
    return request({
        url: `/trade/carts/create/trade`,
        method: 'post',
        data
    })
}
// 支付
export const paycashier = (params) => {
    return request({
        url: `/cashier/pay/${params.paymentMethod}/${params.paymentClient}`,
        method: 'get',
        params
    })
}
// 订单列表
export const oderList = (params) => {
    return request({
        url: `/orders`,
        method: 'get',
        params
    })
}
// 取消订单
export const oderListcancel = (params) => {
    return request({
        url: `/orders/${params.orderSn}/cancel`,
        method: 'post',
        params
    })
}
// 订单详情
export const oderListdetail = (orderSn) => {
    return request({
        url: `/orders/${orderSn}`,
        method: 'get',
    })
}
// 获取营销接口
export const getCouponGoodsList = (params) => {
    return request({
        url: `/promotion/coupon/getCouponGoodsList`,
        method: 'get',
        params
    })
}
// 
export const postmemberEvaluation = (params) => {
    return request({
        url: `/memberEvaluation`,
        method: 'post',
        params
    })
}
// 申请售后
export const postsaveafterSale = (params) => {
    return request({
        url: `/afterSale/save/${params.orderItemSn}`,
        method: 'post',
        params
    })
}
// 售后列表
export const getafterSalepage = (params) => {
    return request({
        url: `/afterSale/page`,
        method: 'get',
        params
    })
}
// 获取物流公司
export const getalogisticsList = () => {
    return request({
        url: `/logistics`,
        method: 'get',
    })
}
// 寄回商品
export const postdeliveryafterSaleSn = (params) => {
    return request({
        url: `/afterSale/delivery/${params.afterSaleSn}`,
        method: 'post',
        params
    })
}
// 取消售后
export const postafterSaleSnfterSaleSn = (afterSaleSn) => {
    return request({
        url: `/afterSale/cancel/${afterSaleSn}`,
        method: 'post',
    })
}
// 售后详情
export const getafterSaleSnfterSaleSn = (afterSaleSn) => {
    return request({
        url: `/afterSale/get/${afterSaleSn}`,
        method: 'get',
    })
}
//查询支付结果
export const getcashierresult = (params) => {
    return request({
        url: `/cashier/result`,
        method: 'get',
        params
    })
}
//查询美白牙膏页面的商品id
export const getshopid = (params) => {
    return request({
        url: `/goods/getGoodsIds`,
        method: 'get',
        params
    })
}
//确认收货
export const postreceiving = (orderSn) => {
    return request({
        url: `/orders/${orderSn}/receiving`,
        method: 'post',
    })
}
//微信登录
export const connectlogin = () => {
    return request({
        url: `/connect/login/web/WECHAT_PC`,
        method: 'get',
    })
}
//信任登录
export const connectresult = (params) => {
    return request({
        url: `/connect/result?uid=` + params.uid,
        method: 'get',
    })
}
//手机号登录验证
export const getUserInfoByPhone = (phone) => {
    return request({
        url: `/members/getUserInfoByPhone/${phone}`,
        method: 'get',
    })
}
//优惠券
export const cartsselectcoupon = (params) => {
    return request({
        url: `/trade/carts/select/coupon`,
        method: 'get',
        params,
    })
}
//优惠券
export const forumshard = (id) => {
    return request({
        url: `/forum/shard/${id}`,
        method: 'PUT',
    })
}
// //签到
// export const memberssign = () => {
//     return request({
//         url: `/members/sign`,
//         method: 'post',
//     })
// }
//屏蔽内容
export const shield = (type) => {
    return request({
        url: `/dentist/isBuy/${type}`,
        method: 'PUT',
    })
}
//已读消息
export const getreads = (type) => {
    return request({
        url: `/memberMsg/readBath/${type}`,
        method: 'PUT',
    })
}